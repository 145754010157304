import { EnvConfig } from 'ssotool-core/config';
import { endpoints, version } from './environment-commons';

export const environment: EnvConfig = {
  name: 'qa',
  production: false,
  oktaAuth: {
    clientId: '0oa9uoew5jlc2iXHR0x7',
  },
  http: {
    timeoutMs: 300000,
    maxRetryAttempts: 2,
    retryScalingDuration: 1000,
    retryIncludedStatusCodes: [401],
    refreshTokenIncludedStatusCodes: [401]
  },
  api: {
    baseUrl: "https://qa.ssotool.te-ded.com/",
    endpoints
  },
  version,
  websocket: {
    url: "wss://wss.qa.ssotool.te-ded.com/",
    keepAlive: true,
    keepAliveIntervalMs: 480000,
    retryOnClose: true,
    retryOnError: true,
    retryScalingDuration: 5000
  },
  sentryDsn: 'https://b056d19c36324c75a6ac8777528aac6d@o258615.ingest.sentry.io/5294356',
  sentryRelease: `ssotool-${version}`,
  homeUrl: 'https://qa.ssotool.te-ded.com/login',
  faqUrl: null,
  trainingUrl: null
};